<template>
  <div class="AuctionsParentView">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'AuctionsParentView'
};
</script>
